<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-select
        v-model="year"
        :items="years"
        prepend-inner-icon="event"
      />
      <v-spacer />
    </v-toolbar>
    <v-alert
      :value="alert"
      type="error"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :loading="loading"
      :expand="expand"
      item-key="month"
      class="elevation-1"
      disable-initial-sort
      hide-actions
    >
      <template #items="props">
        <tr
          :active="props.selected"
          @click="toggleSelection(props)"
        >
          <td class="text-xs-left">
            {{ props.item.month }}
          </td>
          <td class="text-xs-left">
            {{ props.item.paymentId }}
          </td>
          <td class="text-xs-right">
            {{ props.item.income }}
          </td>
        </tr>
      </template>
      <template #expand="props">
        <v-layout column>
          <v-flex xs10>
            <v-card-text color="darken-5">
              <v-data-table
                :headers="detailHeaders"
                :items="props.item.detail"
                item-key="description"
                class="elevation-1 px-3"
                disable-initial-sort
                hide-actions
              >
                <template #items="thisProps">
                  <tr>
                    <td class="text-xs-left">
                      {{ thisProps.item.description }}
                    </td>
                    <td class="text-xs-right">
                      {{ thisProps.item.amount }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-flex>
        </v-layout>
      </template>
      <template #footer>
        <tr>
          <td>&nbsp;</td>
          <td class="text-xs-right">
            Total:
          </td>
          <td class="text-xs-right info">
            RM {{ totalIncome }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </panel>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'

const title = 'UGU Rewards'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      breadcrumbs: [
        {
          text: 'UGU', disabled: false, to: '/ugu',
        },
        {
          text: title, disabled: true,
        },
      ],
      detailHeaders: [
        {
          text: 'Description',
          align: 'left',
          sortable: false,
          value: 'description',
        },
        {
          text: 'Amount (RM)',
          align: 'right',
          sortable: false,
          value: 'amount',
        },
      ],
      expand: false,
      headers: [
        {
          text: 'Month',
          align: 'left',
          sortable: false,
          value: 'month',
        },
        {
          text: 'Payment Id',
          align: 'left',
          sortable: false,
          value: 'paymentId',
        },
        {
          text: 'Reward (RM)',
          align: 'right',
          sortable: false,
          value: 'income',
        },
      ],
      items: [],
      loading: false,
      selected: [],
      title: title,
      totalIncome: '0.00',
      year: null,
      years: [],
    }
  },
  watch: {
    year: function (val) {
      if (val) {
        this.getIncomeStatement(val)
      }
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.year = now.toFormat('yyyy')
  },
  methods: {
    getIncentiveDetails: function (month) {
      const param = { sahabat: true, year: this.year, month }
      const callback = (element) => element.month === month
      const index = this.items.findIndex(callback)
      if (this.items[index].detail.length < 1) {
        this.loading = true
        this.$rest.get('getIncentiveDetails.php', createGetParams(param))
          .then(function (response) {
            this.items[index].detail = response.data.item
            this.loading = false
          }.bind(this)).catch(error => {
            let message = error.message
            if (error.response) {
              message += ': ' + error.response.data
            }
            console.log(message)
            this.loading = false
          })
      }
    },
    getIncomeStatement: function (year) {
      const param = { sahabat: true, year }
      this.loading = true
      this.$rest.get('getIncomeStatement.php', createGetParams(param))
        .then(function (response) {
          this.totalIncome = response.data.totalIncome
          this.items = response.data.item
          if (this.years.length === 0) {
            const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
            const maxYear = now.toFormat('yyyy')
            for (let year = maxYear; year >= response.data.recruitmentYear; year--) {
              this.years.push(year)
            }
            this.maxDate = now.toFormat('yyyy')
          }
          this.loading = false
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alert = true
          this.loading = false
        })
    },
    toggleSelection: function (item) {
      item.selected = !item.selected
      item.expanded = !item.expanded
      if (this.selected.length > 1) this.selected.shift()
      if (item.expanded) {
        this.getIncentiveDetails(item.item.month)
      }
    },
  },
}
</script>
